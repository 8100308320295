<script lang="ts">
export default { name: 'CheckoutLayout' };
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

useHead({
  link: [
    {
      rel: 'stylesheet',
      type: 'text/css',
      href: 'https://static.unzer.com/v1/unzer.css',
    },
  ],
  script: [
    {
      type: 'text/javascript',
      src: 'https://static.unzer.com/v1/unzer.js',
      defer: true,
    },
  ],
});

const { locale } = useI18n();
const router = useRouter();

const linkToHome = `/${locale.value}/`;
const goHome = () => router.push(`/${locale.value}/`);

const config = useRuntimeConfig();
const { apiInstance } = useShopwareContext();
const { getFooterCustomLinks } = useNavigation();
//REVIEW: can we rewrite to just have useNavigation
const { footerBottomLinks } = await getFooterCustomLinks(apiInstance);
// const { notifications } = useNotifications();
</script>
<template>
  <div :class="'theme-' + config.BRAND">
    <LoadingIndicator />
    <LayoutNotifications />
    <div class="checkout">
      <header class="checkout__header">
        <Logo :brand="config.BRAND" :link="linkToHome" @go-home="goHome" />
      </header>
      <slot />
      <MinimalFooter
        :copyright="$t('components.footer.copyright')"
        :links="footerBottomLinks"
      />
    </div>
  </div>
</template>
